import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { HEADER_LEVEL } from "../../utils/commonConst";
import HeaderText from "../common/HeaderText";
import PricingCard from "../common/PricingCard";

import { ClairPricingTierData } from "../../data/homeData";
import { Breakpoints } from "../../styles/styleConstants";
import EmployerGetInTouchLink from "../common/EmployerGetInTouchLink";
import SemiBoldText from "../common/SemiBoldText";
import PricingCardV2 from "../common/PricingCardV2";

export default function ClairPricing(props) {
  const { signupCode } = props ?? {};
  return (
    <>
      <Container id="pricing">
        <ClairPricingWrapper>
          <HeaderWrapper>
            <HeaderText
              level={HEADER_LEVEL.LEVEL_1}
              style={{ margin: "0px 12px 12px 0px" }}
            >
              Special offer for Clair for Employer companies
            </HeaderText>
            <HeaderText
              level={HEADER_LEVEL.LEVEL_4}
              style={{ margin: "12px 0px" }}
            >
              Free 30-day trial of Uprise Core with code CLAIR
            </HeaderText>
          </HeaderWrapper>
          <PricingCardWrapper>
            {ClairPricingTierData.map((clairPricingTier, indx) => {
              return (
                <PricingCardV2
                  signupCode={signupCode}
                  key={`${indx}-ptier-${new Date().getSeconds()}`}
                  pricingTierData={clairPricingTier}
                  className="clair-pricing-card"
                />
              );
            })}
          </PricingCardWrapper>
          {/* <ContactUsWrapper>
            <EmployerGetInTouchLink />
          </ContactUsWrapper> */}
        </ClairPricingWrapper>
      </Container>
    </>
  );
}

const ClairPricingWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  display: block;
  margin: 96px 0px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 40px;
  text-align: center;
`;

const PricingCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  margin-bottom: 40px;
  @media (max-width: ${Breakpoints.sm}) {
    gap: 12px;
    padding: 0px;
    flex-wrap: nowrap;
    overflow-x: scroll;
    justify-content: flex-start;
  }
  .clair-pricing-card {
    width: 448px;
    height: 496px;
    @media (max-width: ${Breakpoints.sm}) {
      min-width: 270px;
      height: 550px;
    }
  }
`;

// const ContactUsWrapper = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
// `;
