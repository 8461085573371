import React from "react";
import Layout from "../layouts/layout";
import Header from "../common/header";
import Footer from "../common/footer";
import LogoBar from "../common/logoBar";
import HowItWorks from "../home/howItWorks";
import CustomerFeedback from "../feedback/customerFeedback";
import styled from "styled-components";
import {
  TrustedBy,
  FeedbackDataV2,
  PressReleaseData,
} from "../../data/homeData";
import { Breakpoints, Spacing } from "../../styles/styleConstants";
import CurveTopBg from "../../images/home/gray-curve-top-bg.png";
import DividerLinesImage from "../../images/home/divider-lines.png";
import TopicsCovered from "../home/topicsCovered";
import { CLAIR_SIGNUP_CODE, QUERY_PARAMS } from "../../utils/commonConst";
import { isNonEmpty, parseReqParams } from "../../utils/commonUtil";
import ClairPricing from "./ClairPricing";
import ClairHero from "./ClairHero";

export default function ClairPartnership(props) {
  const signupCodeReqParamValue = parseReqParams(QUERY_PARAMS.SIGNUP_CODE);
  const signupCode = `${CLAIR_SIGNUP_CODE}${
    isNonEmpty(signupCodeReqParamValue) ? ` - ${signupCodeReqParamValue}` : ""
  }`;

  return (
    <Layout includeHeader={false} includeFooter={false}>
      {/* {process.env.GATSBY_SHOW_BANNER === "true" && <Banner />} */}
      <HeroWrapper>
        <Header />
        <ClairHero signupCode={signupCode} />
      </HeroWrapper>
      <CustomerFeedbackWrapper>
        <CustomerFeedback feedbackData={FeedbackDataV2} hidePricingButton />
      </CustomerFeedbackWrapper>
      <PressReleaseSectionWrapper>
        <LogoBar
          content={PressReleaseData}
          enableWaitlistCta
          signupCode={signupCode}
        />
      </PressReleaseSectionWrapper>
      <DividerLinesWrapper>
        <img src={DividerLinesImage} alt="------" />
      </DividerLinesWrapper>
      <TopicsCovered signupCode={signupCode} />
      <TrustedBySectionWrapper>
        <LogoBar content={TrustedBy} signupCode={signupCode} />
      </TrustedBySectionWrapper>
      <DividerLinesWrapper>
        <img src={DividerLinesImage} alt="------" />
      </DividerLinesWrapper>
      <ClairPricing signupCode={signupCode} />
      <HowItWorks signupCode={signupCode} />
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </Layout>
  );
}

const HeroWrapper = styled.div`
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const CustomerFeedbackWrapper = styled.div`
  padding-top: ${Spacing.xxxxxl};

  @media (max-width: ${Breakpoints.sm}) {
    padding-top: ${Spacing.sm};
  }
`;

const DividerLinesWrapper = styled.div`
  margin-bottom: ${Spacing.xxxxl};

  img {
    width: 100%;
  }

  @media (max-width: ${Breakpoints.sm}) {
    margin-bottom: ${Spacing.xl};
  }
`;

const FaqWrapper = styled.div`
  background: #f9f9f9;
`;

const FooterWrapper = styled.div`
  background-color: white;
  padding-top: ${Spacing.xl};
  background: white url(${CurveTopBg}) top center no-repeat;
  background-size: 100%;
`;

const TrustedBySectionWrapper = styled.div`
  margin-top: ${Spacing.xxl};
`;

const PressReleaseSectionWrapper = styled.div`
  margin-top: -${Spacing.xxxxxl};
`;
